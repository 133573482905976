var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "px-1",
    attrs: {
      "header-bg-variant": "light-primary",
      "header-class": "rounded-lg p-50 flex-column align-items-start",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "fw-800 text-body mb-25"
        }, [_vm._v(" " + _vm._s(_vm.convertISODateTimeLangVN(_vm.ticket.departureTime).shortDay) + " | " + _vm._s(_vm.convertISODateTime(_vm.ticket.departureTime).time) + " - " + _vm._s(_vm.convertISODateTime(_vm.ticket.arrivalTime).time) + " ")]), _c('div', {
          staticClass: "fw-700 d-flex align-items-center"
        }, [_c('span', {
          staticClass: "text-body"
        }, [_vm._v(_vm._s(_vm.ticket.seatGroupCode))]), _c('b-img', {
          staticClass: "m-50",
          attrs: {
            "blank-color": "#ccc",
            "src": require("@icons/dot.svg")
          }
        }), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("Toa " + _vm._s(_vm.ticket.coachNumber))]), _c('b-img', {
          staticClass: "m-50",
          attrs: {
            "blank-color": "#ccc",
            "src": require("@icons/dot.svg")
          }
        }), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("Chỗ " + _vm._s(_vm.ticket.seatName))]), _c('b-img', {
          staticClass: "m-50",
          attrs: {
            "blank-color": "#ccc",
            "src": require("@icons/dot.svg")
          }
        }), _c('span', {
          staticClass: "text-info"
        }, [_vm._v(_vm._s(_vm.ticket.trainCode))])], 1), _c('div', {
          staticClass: "w-100 fw-800 d-flex-between"
        }, [_c('div', [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.ticket.totalPrice * 1000)) + " VND ")]), _c('div', {
          staticClass: "d-flex-center"
        }, [_c('IAmIcon', {
          staticClass: "text-danger",
          attrs: {
            "icon": "clockOutline",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "ml-25 mt-25 mr-1 text-danger"
        }, [_vm._v(_vm._s(_vm.timer))]), _c('b-button', {
          staticClass: "btn-icon p-50 rounded-lg",
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": _vm.deleteHandle
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "trash"
          }
        })], 1)], 1)])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }