<template>
  <b-card
    class="px-1"
    header-bg-variant="light-primary"
    header-class="rounded-lg p-50 flex-column align-items-start"
    no-body
  >
    <template #header>
      <div class="fw-800 text-body mb-25">
        {{ convertISODateTimeLangVN(ticket.departureTime, ).shortDay }}
        |
        {{ convertISODateTime(ticket.departureTime).time }} - {{ convertISODateTime(ticket.arrivalTime).time }}
      </div>

      <div class="fw-700 d-flex align-items-center">
        <span class="text-body">{{ ticket.seatGroupCode }}</span>
        <b-img
          blank-color="#ccc"
          src="@icons/dot.svg"
          class="m-50"
        />
        <span class="text-danger">Toa {{ ticket.coachNumber }}</span>
        <b-img
          blank-color="#ccc"
          src="@icons/dot.svg"
          class="m-50"
        />
        <span class="text-danger">Chỗ {{ ticket.seatName }}</span>
        <b-img
          blank-color="#ccc"
          src="@icons/dot.svg"
          class="m-50"
        />
        <span class="text-info">{{ ticket.trainCode }}</span>
      </div>

      <div class="w-100 fw-800 d-flex-between">
        <div>
          {{ formatCurrency(ticket.totalPrice * 1000) }} VND
        </div>
        <div class="d-flex-center">
          <IAmIcon
            icon="clockOutline"
            class="text-danger"
            size="18"
          />

          <span class="ml-25 mt-25 mr-1 text-danger">{{ timer }}</span>

          <b-button
            variant="danger"
            class="btn-icon p-50 rounded-lg"
            @click="deleteHandle"
          >
            <IAmIcon icon="trash" />
          </b-button>
        </div>
      </div>
    </template>
  </b-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { useInterval } from '@vueuse/core'
import { BCard, BButton, BImg } from 'bootstrap-vue'

import store from '@/store'

import {
  convertISODateTime,
  convertISODateTimeLangVN,
  formatCurrency,
} from '@core/utils/filter'

export default {
  components: {
    BCard,
    BButton,
    BImg,

  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const TOTAL_TIME_HOLD = 60 // 60 * 10 // api
    const timer = ref(TOTAL_TIME_HOLD)

    const {
      counter, // pause,
      // resume, isActive, start, stop,
    } = useInterval(1000)

    function deleteHandle() {
      // pause()
      store.dispatch('app-train/handleToggleSelectTicket', { storeIndex: props.index, ticket: props.ticket })
      // emit('delete', props.index)
    }

    watch(counter, nVal => {
      if (nVal < TOTAL_TIME_HOLD) {
        timer.value -= 1
      } else {
        deleteHandle()
      }
    })

    return {
      timer,
      counter,
      deleteHandle,

      formatCurrency,
      convertISODateTime,
      convertISODateTimeLangVN,
    }
  },
}
</script>
